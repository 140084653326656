:root {
  --primary: #00b880;
  --primary-dark: #007c58;
  --primary-light: #34b78f;

  --accent-green: #31c964;
  --accent-blue: #91d9fd;
  --accent-red: #e44d2e;
  --accent-pink: #f3cfc6;
  --accent-platinum: #e5e4e2;
  --accent-banana: #ffff00;
  --accent-amber: #ffbf00;

  --anchor: #555;
  --anchor-alternative: #777;

  --text-primary: #34424e;
  --text-secondary: #5e778a;
  --text-grey: #444;
  --text-disabled: #adbbc5;

  --alert: #db3f37;
  --caution: #c4861b;
  --app-background: #f5f6fa;
  --app-background-white: #ffffff;
  --separator: #e6e7ed;
  --hover: rgba(0, 0, 0, 0.04);

  --note: #fff8dc;
  --rating-stars: #ffc710;
  --pre-hired: #007c58;
  --input-hover-color: #007c58;
  --suspended: #d49e61;
  --terminated: #bf0a30;
  --lead: #096bd8;

  --available-now: #07c580;
  --load-cluster: #4f9ce0;
  --driver-cluster: #575656;
  --urgent: #ec5040;

  --yesterday-color: #efb428;
  --today-color: #e6594a;
  --tomorrow-color: #17bab2;
  --day-after-tomorrow-color: #a05df5;
  --three-days-later-color: #68b66e;
  --four-days-later-color: #8a451b;
  --five-days-later-color: #db7093;
  --six-days-later-color: #808000;

  /* eta tags */
  --on-time-background: #f6ffed;
  --on-time-border: #b7eb8f;

  --attention-background: #feffe6;
  --attention-border: #fffb8f;

  --late-background: #fff1f0;
  --late-border: #ffa39e;
}

html .christmas-vibes {
  /* Primary Colors */
  --primary: #228b22;
  --primary-dark: #145214;
  --primary-light: #34c35f;

  /* Accent Colors */
  --accent-green: #2e8b57;
  --accent-blue: #91d9fd;
  --accent-red: #ff0000;
  --accent-pink: #ffc0cb;
  --accent-platinum: #e5e4e2;
  --accent-banana: #ffeb3b;
  --accent-amber: #ffd700;

  /* Links and Text Colors */
  --anchor: #333;
  --anchor-alternative: #666;

  --text-primary: #2e2e2e;
  --text-secondary: #4b4b4b;
  --text-grey: #5e5e5e;
  --text-disabled: #b3b3b3;

  /* Alerts and Status */
  --alert: #bf0a30;
  --caution: #ffd700;
  --app-background: #f5f5f5;
  --app-background-white: #ffffff;
  --separator: #dcdcdc;
  --hover: rgba(0, 0, 0, 0.08);

  /* Notes and Highlights */
  --note: #fff8dc;
  --rating-stars: #ffc710;
  --pre-hired: #228b22;
  --input-hover-color: #2e8b57;
  --suspended: #d49e61;
  --terminated: #bf0a30;
  --lead: #01796f;

  /* Availability */
  --available-now: #07c580;
  --load-cluster: #4f9ce0;
  --driver-cluster: #575656;
  --urgent: #e44d2e;

  /* Timeline Colors */
  --yesterday-color: #ffcb47;
  --today-color: #e6594a;
  --tomorrow-color: #17bab2;
  --day-after-tomorrow-color: #a05df5;
  --three-days-later-color: #68b66e;
  --four-days-later-color: #8a451b;
  --five-days-later-color: #db7093;
  --six-days-later-color: #808000;

  /* ETA Tags */
  --on-time-background: #f6fff2;
  --on-time-border: #b7eb8f;

  --attention-background: #fff8dc;
  --attention-border: #ffd700;

  --late-background: #fff1f0;
  --late-border: #ffa39e;
}

/* General Background */
.christmas-vibes body {
  background-color: #f9fafb;
  color: #333;
}

/* Navbar */
.christmas-vibes .navbar {
  background-color: #228b22;
  border-color: #196c19;
}

.christmas-vibes .navbar .navbar-brand {
  color: #ffffff;
}

.christmas-vibes .navbar .navbar-brand:hover {
  color: #e6d700;
}

.christmas-vibes .navbar .navbar-nav > li > a {
  color: #ffffff;
}

.christmas-vibes .navbar .navbar-nav > li > a:hover {
  color: #ffd700;
}

/* Buttons */
.christmas-vibes .btn-primary {
  background-color: #ff0000;
  border-color: #b22222;
}

.christmas-vibes .btn-primary:hover {
  background-color: #b22222;
  border-color: #8b0000;
}

.christmas-vibes .btn-success {
  background-color: #228b22;
  border-color: #196c19;
}

.christmas-vibes .btn-success:hover {
  background-color: #196c19;
  border-color: #0f4d0f;
}

/* Table */
.christmas-vibes .table {
  border: 1px solid #dcdcdc;
}

.christmas-vibes .table th {
  background-color: #e6f4e6;
  color: #333333;
  border-bottom: 2px solid #dcdcdc;
}

.christmas-vibes .table tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.christmas-vibes .table tbody tr:nth-of-type(even) {
  background-color: #f0f8ff;
}

.christmas-vibes .table tbody tr:hover {
  background-color: #e8f5e9;
}

/* Pagination */
.christmas-vibes .pagination .active a,
.christmas-vibes .pagination .active a:focus,
.christmas-vibes .pagination .active a:hover,
.christmas-vibes .pagination .active span,
.christmas-vibes .pagination .active span:focus,
.christmas-vibes .pagination .active span:hover {
  background-color: #ff0000 !important;
  border-color: #b22222 !important;
  color: #ffffff !important;
}

.christmas-vibes .pagination li a,
.christmas-vibes .pagination li span {
  color: #333333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.christmas-vibes .pagination li a:hover,
.christmas-vibes .pagination li span:hover {
  background-color: #ffd700 !important;
  border-color: #ffc107 !important;
  color: #ffffff !important;
}

.christmas-vibes .pagination .disabled a,
.christmas-vibes .pagination .disabled span {
  color: #999;
  background-color: #f9f9f9;
  border-color: #ddd;
}

.christmas-vibes .pagination .disabled a:hover,
.christmas-vibes .pagination .disabled span:hover {
  color: #999;
  background-color: #f9f9f9;
  border-color: #ddd;
}

/* Form Controls */
.christmas-vibes .form-control {
  border-radius: 4px;
  border-color: #dcdcdc;
}

.christmas-vibes .form-control:focus {
  border-color: #228b22;
  box-shadow: 0 0 5px rgba(34, 139, 34, 0.5);
}

/* Alerts */
.christmas-vibes .alert-success {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
}

.christmas-vibes .alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.christmas-vibes .alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.christmas-vibes .alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

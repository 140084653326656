$font-family: Proxima Nova W01 Regular;

$grey-border-color: #cdd6dd;
$success-banner-color: #e0f3ed;
$caution-banner-color: #fff3e5;

@font-face{
  font-family:"Proxima Nova W01 Regular";
  src:url("font/5596735/0284d834-b51e-4b47-b19b-43794ae8adc5.eot?#iefix");
  src:url("font/5596735/0284d834-b51e-4b47-b19b-43794ae8adc5.eot?#iefix") format("eot"),url("font/5596735/b38b8bd9-d96f-4bf9-add1-adbd2b08b802.woff2")
                                                                          format("woff2"),url("font/5596735/52ed7b2d-8a31-4b18-a1d5-8685608b0889.woff")
                                                                          format("woff"),url("font/5596735/50a5dbaa-8f78-4b2e-9546-ff09794682fe.ttf") format("truetype");
}

body {
  background-color: var(--app-background-white);
  font-family: $font-family;
  font-stretch: normal;
  font-style: normal;
}

.hidden {
  display: none;
}

.bold {
  font-weight: bold;
}

.main-text {
  margin-bottom: 10px;
}

.secondary-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
}

.grey-text {
  color: var(--text-secondary);
}

.black-text {
  color: var(--text-primary);
}

.dont-ask-text {
  color: var(--text-primary);
  font-size: 12px;
}

.input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-field {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column-reverse;

  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    padding-bottom: 6px;
  }

  input {
    border-radius: 6px;
    border: solid 1px $grey-border-color;
    background-color: var(--app-background-white);
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 15px;
    padding-left: 15px;
    height: 30px;
    outline: none;

    &:hover {
      border: solid 1px var(--text-secondary);
    }

    &:focus {
      border: solid 1px #007c58;

      + label {
        color: #007c58;
      }

      + .password label {
        color: #007c58;
      }
    }
  }

  input::placeholder {
    color: var(--text-disabled);
    font-family: $font-family;
  }

  .password a {
    float: right;
    text-decoration: none;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 6px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;

  p {
    width: 100px;
    display: inline-block;
    font-size: 14px;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  top: 2px;
  right: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--primary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.primary-button {
  border-radius: 32px;
  margin-top: 10px;
  border: none;
  color: var(--app-background-white);
  background-color: var(--primary);
  font-size: 16px;
  cursor: pointer;
  height: 56px;
  font-family: $font-family;

  &:hover {
    background-color: var(--primary-dark);
    background: var(--primary-dark);
  }

  &:disabled {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #000000;
  }

  &:focus {
    outline: none;
  }
}

.send-button {
  flex-grow: 1;
  border-radius: 32px;
  border: none;
  color: var(--app-background-white);
  background-color: var(--primary);
  font-size: 16px;
  cursor: pointer;
  height: 48px;
  font-family: $font-family;
  margin-left: 12px;
  margin-right: 12px;

  &:hover {
    background-color: var(--primary-dark);
    background: var(--primary-dark);
  }

  &:disabled {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #000000;
  }

  &:focus {
    outline: none;
  }
}

.separator {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $grey-border-color;
}

.separator::before {
  margin-right: 8px;
}

.separator::after {
  margin-left: 8px;
}

#login_microsoft {
  width: 215px;
  height: 41px;
  background-color: var(--app-background-white);
  border: solid 2px $grey-border-color;
  cursor: pointer;
  color: #5e5e5e;
  font-size: 14px;
  font-family: $font-family;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .microsoft-logo {
    height: 20px;
    width: 20px;
    display: block;
    float: left;

    span {
      background-color: #f25022;
      box-shadow:
          10px 0 0 0 #7fba00,
          0 10px 0 0 #00a4ef,
          10px 10px 0 0 #ffb900;
      height: 9px;
      width: 9px;
      float: left;
    }
  }

  &:focus {
    outline: none;
  }
}

.align-center {
  text-align: center;
}

.link-back a {
  text-decoration: none;
  font-size: 14px;
}

.flash-message {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 10px;
}

.success {
  background-color: $success-banner-color;
}

.caution {
  background-color: $caution-banner-color;
}

.message-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--text-primary);
  display: inline;
}

.round-check {
  width: 14px;
  height: 14px;
  object-fit: contain;
  padding-right: 5px;
  vertical-align: middle;
  padding-bottom: 2px;
}

@media only screen and (min-width: 1280px) {
  body {
    margin: 0;
    overflow-y: hidden;
  }

  .row {
    margin-left: 10px;
    margin-right: 10px;
  }

  .login {
    display: flex;
  }

  #white-openroad-logo {
    position: absolute;
    top: 36px;
    left: 40px;

    img {
      height: 60px;
      width: 100px;
    }
  }

  #login-image {
    float: left;
    height: 100vh;

    img {
      height: 100%;
    }
  }

  .login-form {
    width: 460px;
    margin: auto auto;
  }

  .main-text {
    font-size: 34px;
    line-height: 1.29;
    letter-spacing: 0.2px;
  }

  .secondary-text {
    text-align: center;
  }

  .alert-icon-regular {
    width: 14px;
    height: 14px;
    object-fit: contain;
    padding-right: 5px;
    padding-bottom: 2px;
    vertical-align: middle;
  }

  .sign-in-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1279px) {
  .row {
    margin-left: 24px;
    margin-right: 20px;
  }

  #login-image {
    display: none;
  }

  #white-openroad-logo {
    display: none;
  }

  .open-road-logo {
    display: block;
    margin-bottom: 48px;

    img {
      width: 90px;
      height: 50px;
      margin-top: 50px;
    }
  }

  .alert-icon-regular {
    display: none;
  }

  .alert-icon-mobile {
    width: 13px;
    height: 13px;
    object-fit: contain;
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
    padding-bottom: 3px;
  }

  .main-text {
    font-size: 28px;
    line-height: 1.57;
    letter-spacing: 0.16px;
  }
}

@media only screen and (max-width: 360px) {
  .row {
    margin-left: 24px;
    margin-right: 20px;
  }

  #login-image {
    display: none;
  }

  #white-openroad-logo {
    display: none;
  }

  .open-road-logo {
    display: block;
    margin-bottom: 48px;

    img {
      width: 90px;
      height: 50px;
      margin-top: 50px;
    }
  }

  .secondary-text {
    font-size: 13px;
  }

  .alert-icon-regular {
    display: none;
  }

  .alert-icon-mobile {
    width: 13px;
    height: 13px;
    object-fit: contain;
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
    padding-bottom: 3px;
  }

  .main-text {
    font-size: 28px;
    line-height: 1.57;
    letter-spacing: 0.16px;
  }
}

#toast {
  visibility: hidden;
  position: fixed;
  margin-left: -150px;
  padding: 12px;
  box-shadow: 0px 2px 4px rgba(94, 199, 121, 0.16);
  border-radius: 24px;
  font-family: ProximaNova;
  font-size: 14px;
  color: #FFFFFF;
  left: 50%;
  bottom: 50px;
  z-index: 1041;
}

#toast.success {
  background: #007C58;
}

#toast.error {
  background: #DE3D3D;
}

#toast.success::before {
  content: url('../images/success.svg');
  filter: brightness(0) invert(1);
  padding-right: 16px;
}

#toast.error::before {
  content: url('../images/error.svg');
  filter: brightness(0) invert(1);
  padding-right: 16px;
}

#toast::after {
  content: attr(data-after);
  position: relative;
  width: 100%;
  top: -4px;
  white-space: nowrap;
}

#toast.show-toast {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 50px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 50px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 50px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 50px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
